import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'home', // 首页
      component: () => import('@/views/home/index.vue')
    },
    // {
    //   path: '/brand',
    //   name: 'brand', // brand  （品牌）
    //   component: () => import('@/views/home/index.vue')
    // },
    {
      path: '/introduce', // 介绍页面
      name: 'introduce',
      component: () => import('@/views/introduce/index.vue')
    },
    {
      path: '/checkIn', // 品牌入驻首页
      name: 'checkIn',
      component: () => import('@/views/checkIn/index.vue')
    },
    {
      path: '/brandCheckIn', // 品牌入驻表单填写页面
      name: 'brandCheckIn',
      component: () => import('@/views/brandCheckIn/index.vue')
    }
  ]
})
