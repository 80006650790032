export default {
  home: {
    Welcome: '欢迎'
  },
  // 品类汇率
  categoryExchangeRate: {
    Other: '其它', // 其它品类 หมวดหมู่อื่นๆ Other
    electronics: '数码', // 数码 อุปกรณ์ดิจิทัล Electronics
    mobilePhone: '手机', // 手机 โทรศัพท์ Mobile phone
    tablet: '平板', // 平板 แท็บเล็ต Tablet
    computer: '电脑', // 电脑 คอมพิวเตอร์ Computer
    camera: '相机', // 相机  กล้อง Camera
    amusement: '娱乐', // 娱乐 ความบันเทิง Amusement
    smartWearables: '智能穿戴', // 智能穿戴 อุปกรณ์สวมใส่อัจฉริยะ Smart Wearables
    audioVisualEquipment: '影音设备', // 影音设备 อุปกรณ์ภาพและเสียง Audio-visual equipment
    mothertBaby: '母婴', // 母婴产品 แม่และเด็ก Mother & Baby
    pregnantWoman: '孕妇', // 孕妇 แม่ลูกอ่อน Pregnant woman
    infant: '婴幼儿', // 婴幼儿 เด็กทารก Infant
    beauty: '美妆', // 美妝产品 เสริมความงาม Beauty
    makeupRemover: '卸妆', // 卸妆 คลีนซิ่ง Makeup remover
    skinCare: '护肤', // 护肤 บำรุงผิว Skin care
    makeup: '化妆', // 化妆 เครื่องสำอาง Makeup
    householsSupplies: '家居', // 家居用品 ของใช้ในบ้าน Househols Supplies
    householdappliances: '家用电器', // 家用电器 เครื่องใช้ไฟฟ้าในบ้าน Household appliances
    kitchenSupplies: '厨房用品', // 厨房用品 ห้องครัว  Kitchen supplies
    bedding: '床上用品', // 床上用品 ห้องนอน Bedding
    homeDecoration: '家庭装饰', // 家庭装饰 ของตกแต่งบ้าน  Home decoration
    sanitaryLiving: '卫生起居', // 卫生起居 สุขอนามัย Sanitary living
    lighting: '灯饰照明', // 灯饰照明  ดวงไฟและโคมไฟ  Lighting
    houseMaintenance: '房屋维修', // 房屋维修  เครื่องมือ  House maintenance
    controlOfInsect: '害虫防治', // 害虫防治  กำจัดศัตรูพืช  Control of insect
    flowerGardening: '花卉园艺', // 花卉园艺 ทำสวนหย่อม  Flower gardening
    decorationMaterial: '家装材料' // 家装材料 วัสดุ  Decoration material
  },
  //   [
  //   {
  //     label: '数码',
  //     children: [
  //       {
  //         name: '手机',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '平板',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '电脑',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '相机',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '娱乐',
  //         value: '3.7% ~ 3.7%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '智能穿戴',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '影音设备',
  //         value: '3.7% ~ 4.6%',
  //         otherPlatforms: ''
  //       }
  //     ]
  //   },
  //   {
  //     label: '母婴产品',
  //     children: [
  //       {
  //         name: '孕妇',
  //         value: '5.4% ~ 5.4%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '婴幼儿',
  //         value: '5.0% ~ 5.4%',
  //         otherPlatforms: ''
  //       }
  //     ]
  //   },
  //   {
  //     label: '美妆产品',
  //     children: [
  //       {
  //         name: '卸妆',
  //         value: '5.4% ~ 5.4%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '护肤',
  //         value: '5.4% ~ 5.4%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '化妆',
  //         value: '5.4% ~ 5.4%',
  //         otherPlatforms: ''
  //       }
  //     ]
  //   },
  //   {
  //     label: '家居产品',
  //     children: [
  //       {
  //         name: '家用电器',
  //         value: '4.1% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '厨房用品',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '床上用品',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '家庭装饰',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '卫生起居',
  //         value: '4.6% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '灯饰照明',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '房屋装修',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '家装材料',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '害虫防治',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       },
  //       {
  //         name: '花卉园艺',
  //         value: '5.0% ~ 5.0%',
  //         otherPlatforms: ''
  //       }
  //     ]
  //   }
  // ]
  introduce: {
    panning: '淘金达人',
    user: '触达用户',
    text: '让人人都能轻松赚钱！',
    text2: '全新的品牌自营平台',
    platformGameplay: '平台玩法', // 平台玩法
    activityDescription: '活动说明', // 活动说明
    companyProfile: '公司简介', // 公司简介
    mission: '使命愿景', // 使命愿景
    analysis: '市场分析', // 市场分析
    businessModel: '业务模型', // 业务模式
    coreAdvantages: '核心优势', // 核心优势
    coreOverview: '核心概述', // 核心优势
    from: {
      headText: '登录即注册', // 登录即注册
      headText2: '设置密码', // 设置密码
      openBeta: '公测说明', // 公测说明
      nameRecommend: '推荐', // Namsai推荐
      phonePrompt: '请输入手机号', // 请输入手机号
      codePrompt: '请输入验证码', // 请输入验证码
      invitationCode: '请输入邀请码（必填）', // 请输入邀请码（必填）
      btnTextCode: '获取验证码', // 获取验证码
      btnTextCode2: '重新获取', // 重新获取
      btnTextLogin: '登录', // 登录
      agreementText: '继续即表示您的已同意', // 继续即表示您的已同意
      agreementText2: '《MXCOME服务协议与隐私政策》', // MXCOME服务协议与隐私政策
      passwodPrompt: '请输入密码', //请输入密码
      passwodPrompt2: '请再输入一遍', //请再输入一遍
      btnTextConfirm: '确定', // 确定
      Prompt: '密码应由字母加数字组成，首字母必须大写，长度最少8位  请妥善保管密码，并避免使用简单密码组合', // 密码提示
      text: '恭喜，获得总额 ',
      text2: ' 代金券',
      text3: ' 代金券可购买平台任意商品',
      Login_mobile_error: '手机号格式错误', //手机号格式错误
      Login_password_format_error: '密码格式错误，请按照下面格式填写', //密码格式错误，请按照下面格式填写
      Login_password_or_confirm_password_error: '您输入的密码不一致，请重新输入', // 您输入的密码不一致，请重新输入
      congratulations: '恭喜，获得', //
      openBetaText: '公测邀请资格', // 公测邀请资格
      openBetaTextB: '登陆APP参与红包闯关，多推多得', // 登陆APP参与淘金任务
      internalText: ' 红包闯关资格', // 内部邀请资格
      internalTextB: '登陆APP，首页参与指定套餐任务', // 登陆APP体验并参与淘金任务，解锁淘金新功能
      redEnvelope: ' 红包闯关资格', // 红包闯关资格
      redEnvelopeB: '登陆APP，首页参与指定套餐任务' // 登陆APP参与红包闯关，多推多得
    }
  },
  // checkIn页面
  checkIn: {
    title: '全新品牌自营平台', // 全新品牌自营平台
    text: '3 分钟即可完成品牌入驻申请', // 3 分钟即可完成品牌入驻申请
    text2: '48 小时内完成审批及密码发放', // 48 小时内完成审批及密码发放
    text3: '《MXCOME品牌商家入驻协议》', // 《MXCOME品牌商家入驻协议》
    start: '快速入驻' // 快速入驻
  },
  // 政策 policy
  policy: {
    monthlySalesAmount: '月销售金额 ฿',
    marginLevel: '保证金比例',
    marginPolicy: {
      text: '保证金政策',
      textA:
        '为高质量服务广大用户，确保品牌方持续提供优质产品、服务，促进共同发展，平台执行分级保证金政策，截止至2023年12月31日，政策如下：',
      textB: '品牌店铺前1,000单交易无需缴纳保证金',
      textC: '1,000单交易完成，系统自动核算销售金额并执行分级保证金设置，每月28日核定销售金额并自动调整',
      textD: '退出平台后，30天内返还保证金'
    }, // 保证金政策
    technicalServiceFeePolicy: {
      text: '技术服务费政策',
      textA:
        ' MXCOME是共享经济，定位品牌自营平台，致力为广大社会群体及品牌方创造长期价值，鉴于品牌运营公司可能存在多品牌，平台执行技术服务费政策，截止至2023年12月31日，政策如下：',
      textB: '单一品牌无需缴纳技术服务费',
      textC: '同一账户新增品牌店铺时，平台将收取技术服务费，收费标准为每家店铺 10万泰铢/年'
    } // 技术服务费政策
  },
  // brandCheckIn 页面
  brandCheckIn: {
    brandEntry: '品牌录入', // 品牌录入
    contactInformation: '联络信息', // 联络信息
    contactName: '联系人姓名', // 联系人姓名
    inputPhone: '输入联系电话', // 输入联系电话
    Email: 'E-mail', // E-mail
    brandMessage: '品牌信息', //品牌信息
    brand: '品牌名称', //品牌名称
    brandCategories: '品牌类目', // 品牌类目
    relatedWebsites: '相关网站', // 相关网站
    addLink: '新增链接', // 新增链接
    Data: '相关资料', // 相关资料
    brandBrochure: '品牌宣传册', // 品牌宣传册
    nextStep: '下一步', // 下一步
    pleaseSelect: '请选择', // 请选择
    Established: '已设立', // 已设立
    notEstablished: '未设立', // 未设立
    localOperatingCompany: '本地运营公司', // 本地运营公司
    text: '已设立运营公司可以直接激活店铺开展运营', // 已设立运营公司可以直接激活店铺开展运营
    text2: '未设立运营公司可以通过平台帮助完成设立', // 未设立运营公司可以通过平台帮助完成设立
    brandInformation: '品牌资料', //品牌资料
    brandSide: '品牌方', // 品牌方
    jointVenture: '合资公司', // 合资公司
    nationalAgency: '国家代理', // 国家代理
    trademarkApplicationForm: '商标申请书', // 商标申请书
    trademarkCertificate: '商标证书', // 商标证书
    certificateOfIncorporation: '(品牌)商标授权书', // (品牌)商标授权书
    registrationCertificateS: '公司登记证号', // 公司登记证号
    text3: '请根据实际情况上传相关材料，您也可激活店铺后补充认证', // 请根据实际情况上传相关材料，您也可激活店铺后补充认证
    authenticationInformation: '补充认证', // 补充认证
    companyInformationS: '公司资料', // 公司资料
    directorSsignature: '董事签署', // 董事签署
    authorizedSignature: '授权签署', // 授权签署
    registrationCertificate: '公司登记证书', // 公司登记证书
    affidavit: '公司宣誓书', // 公司宣誓书
    effectiveDate: '有效截止日期', // '有效截止日期'
    effectiveDateS: '请选择截止日期', // '请选择截止日期'
    Enter: '请输入', // '请输入'
    taxAdministration: '税务登记证', // '税务登记证
    taxAdministrationS: '税务登记证号', // '税务登记证号'
    text4: '公司认证资料需董事或授权代表签字盖章', // '公司认证资料需董事或授权代表签字盖章'
    directorEmpower: '董事授权书', // 董事授权书
    directorEmpowerTitle: '董事名字、授权代表名字', // 董事名字、授权代表名字
    continuouslyIncreasing: '持续增加中...', // 持续增加中...
    text6: '单张图片大小不超过3MB，支持jpg、png、pdf、bmp、jpeg、heic格式', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: '单个文件不能超过10MB,支持pdf', // 单个文件不能超过10MB,支持pdf
    text8: '品牌名称不能重复，请重新输入', // 品牌名称不能重复，请重新输入
    submitApplication: '提交申请', // '提交申请'
    text5: '申请已成功提交', // '申请已成功提交'
    receiveEmail: '接收邮件：', // '接收邮件：'
    receiveRemind: '接收提醒：', // '接收提醒：'
    complete: '完成', // '完成'
    continuingCertification: '继续认证' // 继续认证
  },
  // 合作成功页
  complete: {
    brandEntry: '品牌录入', // 品牌录入
    confirm: '确认', // 确认
    submitForReview: '提交审核', // 提交审核
    successfullySubmitted: '入驻申请已成功提交', // 入驻申请已成功提交
    receiveAuditReminders: '接收审核提醒', // 接收审核提醒
    receiveKeyEmail: '接收密钥邮件', // 接收密钥邮件
    textA: '如选择完成,可用密钥登录店铺后补充认证', // 如选择完成，可用密钥登录店铺后补充认证
    textB: '如资料完全,您可继续认证,节约审核时间', // 如资料完全，您可继续认证，节约审核时间
    complete: '完成', // 完成
    continuingCertification: '继续认证', // 继续认证
    supplementaryCertification: '补充认证', // 补充认证
    textC: '请根据实际情况上传相关资料，您也可激活店铺后补充认证', // 请根据实际情况上传相关资料，您也可激活店铺后补充认证
    textD: '48小时内完成审核及密钥发放' // 48小时内完成审核及密钥发放
  },
  cooperate: {
    messageA: '正在开发中,敬请期待!', // 正在开发中,敬请期待期待!
    message: '您的补充认证资料已提交成功！', // 补充提交提示语
    includingTax: ' 含税', // 含税
    viewPolicy: '查看政策', // 查看政策
    text: '30天免费', // 30天免费
    textA: '前 1,000 单免缴', // 前 1,000 单免缴
    textB: '单一品牌店铺免费', // 单一品牌店铺免费
    textC: '2023免费', // 2023免费
    shopWithdrawalFee: '店铺提现手续费', // 店铺提现手续费
    textD: '第三方收取', // 第三方收取
    textE: '以上收费项已享受MXCOME 2023年品牌优惠政策', // 以上收费项已享受MXCOME 2023年品牌优惠政策
    textF: '优惠政策截止至2023年12月31日,解释权归属平台', // 优惠政策截止至2023年12月31日，解释权归属平台
    tabText: '真实案例', // 真实案例
    tabText2: '收费标准', // 收费标准
    btnText: '立即入驻', // 立即入驻
    CategoryRate: '品类费率', // 品类费率
    Margin: '店铺保证金', // 保证金
    annualFee: '技术服务年费', // 技术服务年费
    OtherPlatforms: '其他平台', // 其他平台
    text2: '2023限免'
  },
  menus: {
    activity: '精彩活动',
    news: '新闻中心',
    aboutUs: '关于我们',
    partenship: '平台合作',
    joinUs: '加入我们'
  },
  nav: {
    play: '越玩越赚',
    login: '运营登录',
    centerTitle: '欢迎移民淘金世界',
    centerTetx: '品牌自营 共创未来'
  },
  bottom: {
    innovation: '创新',
    transform: '改变',
    future: '未来',
    Copyright: 'MXCOME © 版权所有 2023'
  },
  // home: {
  //   head: '轻松赚钱是一种生活方式',
  //   p2: '全新网购、社交、内容场景等你来玩！',
  //   p3: '激发创造力、内容变现简单高效',
  //   head2: '迈入品牌自营的全新时代',
  //   p4: '重构电商场景，品牌自营即渠道变革！',
  //   p5: '分享即正义，持续赋能品牌成长',
  //   endTime: '剩余时间',
  //   deadline: '截止时间',
  //   scan: '请扫码参与活动',
  //   quickly: '30S淘金达人速成',
  //   checkpoint: '关卡',
  //   countZero: '倒计时',
  //   day: '天',
  //   hours: '时',
  //   minute: '分',
  //   second: '秒',
  //   top1000: '前1000名淘金达人将免费获得以下排名大奖',
  //   search: '查询活动及中奖',
  //   Subscription: '淘金  营销订阅',
  //   emailAddress: '请输入邮箱地址',
  //   subscribe: '订阅',
  //   message: '我们将不定期发放优惠券及品牌活动通知',
  //   downInfo: '移动设备扫码安装',
  //   NoActivity: '暂无活动！'
  // },
  news: {
    newsCenter: '新闻中心',
    MNews: 'MXCOME News',
    official: '直击市场动态，MXCOME官方权威发布',
    productNews: '产品消息',
    dynamic: '运营动态',
    report: '媒体报道',
    statement: '官方声明',
    search: '搜索历史新闻动态',
    readOn: '请继续阅读'
  },
  aboutUs: {
    brochure: '官方宣传册',
    Value: 'Value Co-Creation',
    Win: 'Win-Win',
    economics: '价值共创 互利共赢',
    highTech: '我们致力成为可持续发展的社会型伟大科技企业',
    web: '专注于人工智能、数字化、Web3为核心技术驱动的共享经济商业生态',
    profit: '主要利润来自平台服务及广告',
    flagship: 'MXCOME / 淘金 定位为社交推荐平台，属共享经济',
    mega: '我们希望建立轻松赚钱的生活方式，致力帮助多元化社会群体获得可持续增长的收益，同时革新现有电商销售模式',
    // mega: 'Mega content production, XEFR infinite possibility ; Come to smart Eco of TAO',
    // infinitePossibilities: '',
    infinitePossibilities: '超级内容生产，传递无限可能；进入道的智慧生态',
    zoology: '进入道的智慧生态',
    Spirit: 'The Spirit of MXCOME',
    soul: '淘金之魂',
    message: '创始寄语',
    // messageTip: `${'正义创新'&nbsp;'敬畏自然'&nbsp;'热爱生活'}`, `${&nbsp;}`
    messageTip: '正义创新 \xa0\xa0 敬畏自然 \xa0\xa0 热爱生活',
    values: '价值观',
    valuesTip: '服务全球',
    valuesTip1: '以上品牌',
    // valuesTip: '敬天爱人 \xa0\xa0 守正出奇',
    vision: '愿景',
    visionTip: '让人人都能轻松赚钱',
    // visionTip: '构建全新商业秩序',
    mission: '使命',
    missionTip: '敬天爱人，守正出奇',
    contactUs: '与我们取得联系',
    contact: '联系',
    ReceiveTime: '接待时间：9:00-18:00(周六、周日及公众假期休息)',
    Mailbox: '总务邮箱',
    harken: '我们用心倾听',
    proposal: '无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。有效建议被采纳，您将有机会获得500-5000金币奖励',
    award: '有效建议采纳将有机会获得500-5000淘金金币奖励',
    configSuggest: '立即建议',
    office: '我们的办公室',
    address: '曼谷市-Bang khen区-Tha Raeng街道-Soi Ramintra-32路-正6巷口【邮编10220】',
    attention: '关注我们',
    moreContent: '更多精彩内容',
    userSuggest: '用户建议',
    name: '姓名',
    region: '区域',
    thai: '泰国',
    china: '中国',
    ID: 'MXCOME ID',
    phone: '联系电话',
    suggestContent: '请以文字描述建议内容',
    upload: '上传附件',
    uploadTips: '仅限10MB大小，文件格式支持（PDF WORD）',
    submit: '提交',
    GoHere: '去这里',
    copySuccess: '复制成功'
  },
  join: {
    joinM: 'Join MXCOME',
    welcome: 'Welcome to join the MXCOME family',
    btnThai: '泰国',
    btnCh: '中国',
    changeWork: '想换工作？MXCOME诚邀您加入。',
    joinUs: '加入我们',
    whichWork: '想换什么工作？',
    JobTitle: '请输入职位名称',
    addressDetail: '详细地址',
    workplacePlaceholder: '请选择城市办公室',
    workplace: '工作地点',
    thai: '泰国',
    china: '中国',
    usa: '美国',
    BangkokStudio: '曼谷工作室',
    ChangshaStudio: '长沙工作室',
    ShenzhenStudio: '深圳工作室',
    openPositions: '开放的岗位',
    controlCenter: 'A.管理中心',
    ProductCenter: 'T.产品中心',
    businessCenter: 'B.商业中心',
    required: '技能与资格要求',
    department: '部门',
    design: '设计',
    research: '研发', //research and development
    assetManagement: '资管',
    hr: '人资',
    generalOffice: '总办', //General Office
    planning: '企划',
    operate: '运营',
    choose: '请选择',
    type: '职位类型',
    FullTime: '全职',
    partTime: '兼职',
    practice: '实习',
    recruitmentPolicies: '招聘政策',
    procedure: '入职流程',
    redLine: '职业红线',
    markAsHoliday: '标记为放假日',
    somePublicDaysHaveAdjust: '部分公众日存在调整',
    holidayCalendar: '放假日历'
  },
  collaboration: {
    invite: 'Invite immigrants to MXCOME World',
    together: 'Strictly abide by the contract and cooperate sincerely to benefit the world and create the future together',
    multi: '平台合作',
    thai: '泰国',
    china: '中国',
    interaction: '活动合作',
    platformOnboard: '申请品牌入驻',
    events: '承办品牌专场',
    information: '投递合作资料',
    circular: '官方审核通告',
    only: '仅限10MB大小，文件格式支持（PDF WORD EXCEL)',
    search: '搜索品牌名称',
    category: '类别',

    // 平台合作
    TypeCooperation: '合作类型',
    brand: '品牌',
    CheckInTime: '入驻时间',
    OfficialAuditCircular: '官方审核通告',
    BrandSettlement: '品牌入驻',
    BrandHome: '品牌归属地',
    BrandName: '品牌名称',
    ContactName: '联系人名称',
    AreaCode: '区号',
    ContactNumber: '联系电话',
    emailBrand: '邮箱',
    webBrand: '品牌官网',
    SubmitApplication: '提交申请',
    YourApplicationPleaseWaitGoodNews: '您的申请已提交，请静候佳音',
    PleaseKeepPhoneOpen: '请保存电话畅通，如有必要我们将向您致电',
    PleaseKeepPhoneOpen2: '我们将于48小时内完成审核，并通过短信及邮件方式发送',
    know: '知道了',

    LOGO: 'LOGO',
    deliveryTime: '投递时间',
    result: '申请结果',
    remark: '备注',
    pass: '通过',
    reject: '拒绝',
    SONY: 'SONY泰国'
  }
}
