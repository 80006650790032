import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import ios from './utils/ios'
// 导入资源文件
import { i18n, vantLocales } from './lang/index'
ios()
Vue.use(Vant) // 注册全局组件
console.log('当前的语言是：', i18n.locale)
// vant-ui组件国际化
vantLocales(i18n.locale)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
